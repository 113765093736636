import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Container } from "./styled";
import FormConsultation from "../../../containers/FormsThird/Consultation-Classic";
import { Form } from "antd";
import { LocationContext } from "../../../context/LocationContext";

const ContactFormDropdown = ({ $isScrolled, isOpen, onClose }) => {
  const [closestShowroom, setClosestShowroom] = useState(null);
  const [form] = Form.useForm();
  const locationContext = useContext(LocationContext);
  const formInitializedRef = useRef(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  
  // Mark component as mounted
  useEffect(() => {
    setIsMounted(true);
  }, []);
  
  // Detect iOS devices
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) || 
                         (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      setIsIOS(isIOSDevice);
      console.log("ContactFormDropdown: iOS detection:", isIOSDevice);
    }
  }, []);
  
  // Reset form when isOpen changes
  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
      formInitializedRef.current = false;
    }
  }, [isOpen, form]);

  const handleFormSuccess = (response) => {
    console.log('handleFormSuccess called with response:', response);
    
    if (!response) {
      console.error('No response data received in handleFormSuccess');
      return;
    }
    
    // Log the CRM response with all form fields
    const logData = {
      timestamp: new Date().toISOString(),
      formId: 'header-quick-contact',
      response: response,
      formData: response.formData || {},
      crmResponse: response.crmResponse,
      emailResponse: response.emailResponse,
      fbResponse: response.fbResponse,
      location: {
        city: locationContext?.city,
        state: locationContext?.state,
        tenantId: locationContext?.tenantId
      }
    };
    
    console.log('CRM Lead Submission Response:', logData);

    // Log to server
    console.log('Sending log to server...');
    fetch('/api/log-crm-submission', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(logData),
    })
    .then(res => res.json())
    .then(data => {
      console.log('Server log response:', data);
    })
    .catch(error => {
      console.error('Failed to log CRM submission:', error);
    });

    // Reset form after logging
    console.log('Resetting form...');
    form.resetFields();

    // Close the dropdown after a short delay to allow the success modal to show
    console.log('Closing dropdown...');
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 100);
  };

  // Use LocationContext to get location data instead of polling localStorage
  useEffect(() => {
    if (!locationContext || formInitializedRef.current || !isMounted) return;
    
    try {
      console.log("ContactFormDropdown: LocationContext data received", locationContext);
      
      // Check if we have valid location data
      if (locationContext && locationContext.city && locationContext.state) {
        const formattedShowroom = `${locationContext.city}, ${locationContext.state}`;
        console.log("ContactFormDropdown: Setting closest showroom", formattedShowroom);
        
        setClosestShowroom(formattedShowroom);
        
        // Only set form values if the form is open
        if (isOpen) {
          console.log("ContactFormDropdown: Setting form values", {
            showroom: formattedShowroom,
            tenantId: locationContext.tenantId,
            isIOS
          });
          
          // Use longer timeout for iOS devices
          const timeoutDuration = isIOS ? 300 : 100;
          
          // Use setTimeout to ensure the form is fully rendered
          setTimeout(() => {
            form.setFieldsValue({
              showroom: formattedShowroom,
              tenantId: locationContext.tenantId
            });
            formInitializedRef.current = true;
            setIsFormReady(true);
            
            // For iOS, double-check the values were set correctly
            if (isIOS) {
              setTimeout(() => {
                const currentValues = form.getFieldsValue();
                console.log("ContactFormDropdown: iOS form values check:", currentValues);
                
                // If values weren't set correctly, try again
                if (!currentValues.showroom || currentValues.showroom !== formattedShowroom) {
                  console.log("ContactFormDropdown: iOS retry setting form values");
                  form.setFieldsValue({
                    showroom: formattedShowroom,
                    tenantId: locationContext.tenantId
                  });
                }
              }, 200);
            }
          }, timeoutDuration);
        }
      } else {
        console.warn("ContactFormDropdown: LocationContext missing city or state", locationContext);
        setIsFormReady(true); // Still mark as ready so form can be used manually
      }
    } catch (error) {
      console.error("ContactFormDropdown: Error setting showroom data", error);
      setIsFormReady(true); // Still mark as ready so form can be used manually
    }
  }, [locationContext, form, isOpen, isIOS, isMounted]);

  // When the form opens, ensure it has the latest location data
  useEffect(() => {
    if (!isMounted) return;
    
    if (isOpen && locationContext && !formInitializedRef.current) {
      console.log("ContactFormDropdown: Form opened, initializing with location data");
      
      try {
        if (locationContext.city && locationContext.state) {
          const formattedShowroom = `${locationContext.city}, ${locationContext.state}`;
          console.log("ContactFormDropdown: Form opened, setting showroom to", formattedShowroom);
          
          // Use longer timeout for iOS devices
          const timeoutDuration = isIOS ? 300 : 100;
          
          // Use setTimeout to ensure the form is fully rendered
          setTimeout(() => {
            form.setFieldsValue({
              showroom: formattedShowroom,
              tenantId: locationContext.tenantId
            });
            formInitializedRef.current = true;
            setIsFormReady(true);
            
            // For iOS, double-check the values were set correctly
            if (isIOS) {
              setTimeout(() => {
                const currentValues = form.getFieldsValue();
                console.log("ContactFormDropdown: iOS form values check after open:", currentValues);
                
                // If values weren't set correctly, try again
                if (!currentValues.showroom || currentValues.showroom !== formattedShowroom) {
                  console.log("ContactFormDropdown: iOS retry setting form values after open");
                  form.setFieldsValue({
                    showroom: formattedShowroom,
                    tenantId: locationContext.tenantId
                  });
                }
              }, 200);
            }
          }, timeoutDuration);
        } else {
          setIsFormReady(true);
        }
      } catch (error) {
        console.error("ContactFormDropdown: Error initializing form on open", error);
        setIsFormReady(true);
      }
    }
  }, [isOpen, locationContext, form, isIOS, isMounted]);

  // Fixed position at the right edge
  const dropdownStyle = {
    position: 'absolute',
    right: '65px',
    top: '60px', // This matches the height of the header
    width: '400px',
    display: isMounted && isOpen ? 'block' : 'none',
    visibility: isMounted ? 'visible' : 'hidden' // Hide before client-side hydration
  };

  console.log('ContactFormDropdown render, isOpen:', isOpen, 'isFormReady:', isFormReady, 'isIOS:', isIOS);

  // Instead of conditionally rendering the component (which causes hydration issues),
  // we'll always render it but control visibility with CSS
  return (
    <Container
      $isScrolled={$isScrolled}
      style={dropdownStyle}
      className="contact-form-dropdown"
      aria-hidden={!isOpen}
    >
      <div className="form-container">
        <h3 className="form-heading">Schedule your FREE Consultation</h3>
        <FormConsultation 
          formId="header-quick-contact"
          variant="small"
          submitButton="Get Started"
          form={form}
          onSuccess={handleFormSuccess}
          initialValues={closestShowroom ? {
            showroom: closestShowroom,
            tenantId: locationContext?.tenantId
          } : undefined}
          isFormReady={isFormReady && isMounted}
          isOpen={isOpen}
          onClose={onClose}
        />
      </div>
    </Container>
  );
};

ContactFormDropdown.propTypes = {
  $isScrolled: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default ContactFormDropdown; 