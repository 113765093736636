import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  z-index: 999;
  background-color: ${props => props.theme.colors.classyRed || '#a51c1c'};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  right: 0;
  top: 520px;
  padding: 20px;
  width: 400px;
  transition: all 0.3s ease;
  
  .form-container {
    /* Form heading styling */
    .form-heading {
      color: white;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      margin-bottom: 15px;
      white-space: nowrap;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 0.5px;
    }
    
    /* Override form styles directly */
    .ant-form-item-label > label {
      color: white;
      font-size: 8px !important;
    }
    
    /* Make all form labels 8px */
    .ant-form-item-label {
      font-size: 10px !important;
    }
    
    label, .ant-form-item-label label {
      font-size: 10px !important;
    }
    
    .ant-input {
      background-color: rgba(255, 255, 255, 0.9);
      border-color: white;
    }
    
    /* Select component styling for dark text */
    .ant-select .ant-select-selector {
      background-color: rgba(255, 255, 255, 0.9) !important;
      border-color: white !important;
    }
    
    .ant-select .ant-select-selection-placeholder {
      color: #666 !important;
    }
    
    .ant-select .ant-select-selection-item {
      color: #333 !important;
    }
    
    .ant-select .ant-select-arrow {
      color: #333 !important;
    }
    
    /* Button styling */
    .ant-btn {
      background-color: #762738 !important;
      border: 2px solid white !important;
      color: white !important;
      font-weight: bold !important;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    }
    
    .ant-btn:hover {
      background-color: #8a2e42 !important;
      border-color: white !important;
    }
    
    .ant-btn span {
      color: white !important;
    }
    
    .ant-form-item-explain, 
    .ant-form-item-extra {
      color: rgba(255, 255, 255, 0.85);
      font-size: 8px !important;
    }
    
    /* Style any text in the form to be white EXCEPT select component */
    p, span:not(.ant-select-selection-item):not(.ant-select-selection-placeholder), label {
      color: white;
    }
    
    /* Reduce spacing between form items for a more compact form */
    .ant-form-item {
      margin-bottom: 8px;
    }
      .ant-layout-header {line-height:32px !important;}
  }
`; 